import { getLocalStorageInfo } from "../../utils";
import { jetStreamInstance } from "../Leases";
export const invoiceType = [
  {label: 'Reserve', value: '1'}, 
  {label: 'Rental', value: '2' }, 
  {label: 'Adhoc', value: '9'}, 
  {label: 'Penalty', value: '3'}, 
  {label: 'Debit', value: '4'},
  {label: 'Credit', value: '5'}, 
  {label: 'Security Deposit', value: '6'}, 
  {label: 'Insurance', value: '7'}, 
  {label: 'Insurance Claim', value: '8'}
];
export const invoiceStatus = [
  {label: 'Draft', value: '1'}, 
  {label: 'Sent', value: '2' }, 
  {label: 'Partially-Paid', value: '5'}, 
  {label: 'Paid', value: '3'}, 
  {label: 'Cancelled', value: '4'}
]
export const globalFilterTypes = [
  {label:'lessee',type:'lesseeOptions'},
  {label:'lessor',type:'lessorOptions'},
  {label:'owner',type:'ownerOptions'},
  {label:'asset_type',type:'assetTypeOptions'},
  {label:'asset_model',type:'assetModelOptions'},
  {label:'serial_number',type:'assetsOptions'},
]
export const invoiceListHd = [
  { label: 'Invoice Number', sortOption: false },
  { label: 'Type', sortOption: false },
  { label: 'Invoice Period', sortOption: false },
  { label: 'Invoice Date', sortOption: false },
  { label: 'Invoice Due Date', sortOption: false },
  { label: 'Amount', sortOption: false },
  { label: 'Status', sortOption: false }
]
export const invoiceFleetHd = [
  { id:'asset_type', label: 'Asset(s)', sortOption: true },
  { id:'lessor_name', label: `${jetStreamInstance?'Lessor/Holdco':'Lessor'}`, sortOption: false },
  { id:'owner_name', label: `${jetStreamInstance?'Owner/SPV':'Owner'}`, sortOption: false },
  { id:'lessee_id', label: 'Lessee', sortOption: false },
  { id:'invoice_number', label: 'Invoice Number', sortOption: true },
  { id:'invoice_type', label: 'Invoice Type', sortOption: false },
  { label: 'Invoice Status', sortOption: false },
  { id:'total_amount', label: 'Amount', sortOption: true },
  { label: 'Invoice Period', sortOption: false },
  { id:'date', label: 'Invoice Date', sortOption: true },
  { id:'due_date', label: 'Due Date', sortOption: true }
]
export const creditDebitHd = [
  {label:'Description', sortOption:false},
  {label:'Amount', sortOption:false},
  {label:'Tax Rate (%)', sortOption:false},
  {label:'Tax Amount', sortOption:false},
  {label:'Total (USD)', sortOption:false}
];
export const penaltyHd = [
  {label:'Over Due Amount', sortOption:false},
  {label:'Description', sortOption:false},
  {label:'Due Date', sortOption:false},
  {label:'Payment Date', sortOption:false},
  {label:'No Of Days over due', sortOption:false},
  {label:'6 Months USD LIBOR (%)', sortOption:false},
  {label:'Additional Rate(%)', sortOption:false},
  {label:'Total Penal Interest Rate', sortOption:false},
  {label:'Interest On Over Due Amount', sortOption:false},
];
export const mntRentalHd = [
  {label:'Item', sortOption:false},
  {label:'Description', sortOption:false},
  {label:'Unit Description', sortOption:false},
  {label:'No. Of Units', sortOption:false},
  {label:'Fixed/Per Unit Rate', sortOption:false},
  {label:'Tax Rate (%)', sortOption:false},
  {label:'Tax Amount', sortOption:false},
  {label:'Sub Total', sortOption:false},
];
export const errorCode = {
  invoice_number:{
    0:'',
    1:'Please enter Invoice Number'
  },
  invoice_numberObj:{
    required:true
  },
  date:{
    0:'',
    1:'Please enter Invoice Date',
    5:'Please enter valid Date'
  },
  dateObj:{
    required:true,
    datePattern:true
  },
  due_date:{
    0:'',
    1:'Please enter Invoice Due Date',
    5:'Please enter valid Date'
  },
  due_dateObj:{
    required:true,
    datePattern:true
  },
  start_date:{
    0:'',
    1:'Please enter Invoice Start Date',
    5:'Please enter valid Date'
  },
  start_dateObj:{
    required:true,
    datePattern:true
  },
  end_date:{
    0:'',
    1:'Please enter Invoice End Date',
    5:'Please enter valid Date'
  },
  end_dateObj:{
    required:true,
    datePattern:true
  },
  libor_date:{
    0:'',
    1:'Please enter Libor Date',
    5:'Please enter valid Date'
  },
  libor_dateObj:{
    required:true,
    datePattern:true
  },
  libor_value:{
    0:'',
    1:'Please enter Libor Value'
  },
  libor_valueObj:{
    required:true
  },
  additional_rate:{
    0:'',
    1:'Please enter Additional Rate'
  },
  additional_rateObj:{
    required:true
  },
  to_company_name:{
    0:'',
    1:'Please enter Company Name'
  },
  to_company_nameObj:{
    required:true
  },
  from_company_name:{
    0:'',
    1:'Please enter Company Name'
  },
  from_company_nameObj:{
    required:true
  },
}
export const statusErrorCode = {
  status: {
    0:'',
    1:'Please select Status'
  },
  statusObj:{
    required: true
  },
  partial_paid_amount: {
    0:'',
    1:'Please enter Partial Payment Amount'
  },
  partial_paid_amountObj:{
    required: true
  },
  paid_date:{
    0:'',
    1:'Please enter Payment Date',
    5:'Please enter Valid Date'
  },
  paid_dateObj:{
    required: true,
    datePattern:true
  },
  partial_paid_date:{
    0:'',
    1:'Please enter Partial Payment Date',
    5:'Please enter Valid Date'
  },
  partial_paid_dateObj:{
    required: true,
    datePattern:true
  },
}
export const invoiceFilterOps = {
  invoice_type: {
    inputType: 'dropdown',
    placeholder: 'Select Invoice Type',
    options: [{label: 'MR Invoice', value: '1'}, {label: 'Rental Invoice', value: '2'}, {label: 'Penalty Invoice', value: '3'},{label: 'Debit Note Invoice', value: '4'},{label: 'Credit Note Invoice', value: '5'}],
    labelKey: 'label',
    valueKey: 'value',
    title:'Invoice Type',
    multiple: true
  },
  invoice_number: {
    inputType: 'text',
    placeholder: 'Search By Invoice Number',
    title:'Invoice Number'
  },
  status: {
    inputType: 'dropdown',
    placeholder: 'Select Invoice Status',
    options: [{label: 'Draft', value: '1'}, {label: 'Sent', value: '2' }, {label: 'Paid', value: '3'}, {label: 'Cancelled', value: '4'}],
    title:'Invoice Status',
    labelKey: 'label',
    valueKey: 'value',
    multiple: true
  },
  dueDate:{
    inputType: 'date',
    title: 'Due Date',
    firstKey:'dueStartDate',
    lastKey:'dueEndDate',
    range:true
  },
  transactionDate:{
    inputType: 'date',
    title: 'Transaction Date',
    firstKey:'startDate',
    lastKey:'endDate',
    range:true
  }
};

export const invoiceFleetFilterOps = {
  asset_type : {
    inputType: 'dropdown',
    placeholder: 'Select Asset Type',
    title: 'Asset Type',
    options: [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  asset_model: {
    inputType: 'dropdown',
    placeholder: 'Select Asset Model',
    title: 'Asset Model',
    options: [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  serial_number: {
    inputType: 'dropdown',
    placeholder: 'Select Serial No.',
    title: 'Serial No.',
    options: [],
    labelKey: 'serial_number',
    valueKey: 'id',
    multiple: true
  },
  lessee_id : {
    inputType: 'dropdown',
    placeholder: 'Select lessee',
    title: 'Lessee',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  lessor_id : {
    inputType: 'dropdown',
    placeholder: `Select ${jetStreamInstance?'Lessor/Holdco':'Lessor'}`,
    title: `${jetStreamInstance?'Lessor/Holdco':'Lessor'}`,
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
   },
   owner_id : {
    inputType: 'dropdown',
    placeholder: `Select ${jetStreamInstance?'Owner/SPV':'Owner'}`,
    title: `${jetStreamInstance?'Owner/SPV':'Owner'}`,
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
   },
  invoice_type: {
    inputType: 'dropdown',
    placeholder: 'Select Invoice Type',
    options: invoiceType,
    labelKey: 'label',
    valueKey: 'value',
    title:'Invoice Type',
    multiple: true
  },
  invoice_number: {
    inputType: 'text',
    placeholder: 'Search By Invoice Number',
    title:'Invoice Number'
  },
  invoice_status: {
    inputType: 'dropdown',
    placeholder: 'Select Invoice Status',
    options: invoiceStatus,
    title:'Invoice Status',
    labelKey: 'label',
    valueKey: 'value',
    multiple: true
  },
  due_start_date:{
    inputType: 'date',
    title: 'Due Start Date',
  },
  due_end_date:{
    inputType: 'date',
    title: 'Due End Date',
  },
  invoice_start_date:{
    inputType: 'date',
    title: 'Invoice Start Date',
  },
  invoice_end_date:{
    inputType: 'date',
    title: 'Invoice End Date',
  },
  currency: {
    inputType: 'dropdown',
    placeholder: 'Select Currency',
    options: [{label: 'USD', value: 'USD'}, {label: 'EUR', value: 'EUR'}],
    title:'Currency',
    labelKey: 'label',
    valueKey: 'value'
  },
  is_due : {
    inputType: 'dropdown',
    placeholder: 'Search by due invoices',
    title: 'Due Invoices',
    options:  [{label: 'Yes', id: '1'}, {label: 'No', id: '0'}],
    labelKey: 'label',
    valueKey: 'id'
  }
};

export const invTypeGraphFilterOps = {
  invoice_start_date:{
    inputType: 'date',
    title: 'Invoice Start Date',
  },
  invoice_end_date:{
    inputType: 'date',
    title: 'Invoice End Date',
  },
  invoice_type: {
    inputType: 'dropdown',
    placeholder: 'Select Invoice Type',
    options: invoiceType,
    labelKey: 'label',
    valueKey: 'value',
    title:'Invoice Type',
    multiple: true
  },
}

export const invStatusGraphFilterOps = {
  invoice_start_date:{
    inputType: 'date',
    title: 'Invoice Start Date',
  },
  invoice_end_date:{
    inputType: 'date',
    title: 'Invoice End Date',
  },
  invoice_status: {
    inputType: 'dropdown',
    placeholder: 'Select Invoice Status',
    options: invoiceStatus,
    title:'Invoice Status',
    labelKey: 'label',
    valueKey: 'value',
    multiple: true
  }
}
